import {
	AcceptedFileType,
	PlatformIntegrationData,
	FileFetchingDetails,
	FileFetchFunction,
	FileListObject,
	IntegrationData,
} from "../Integrations.types"
import { forwardWithSecurity } from "libs"

interface RecmanFileFetchingDetails extends FileFetchingDetails {
	candidateId: string
	fileId: string
}

const extraDomain = process.env.REACT_APP_IS_TEST === "true" || process.env.REACT_APP_IS_DEV === "true" ? "dev." : ""

const BASE_RECMAN_URL = (method: "get" | "post" | "put") => `https://${extraDomain}api.recman.no/v2/${method}/index.php`

const getFileFromRecman: FileFetchFunction = async (
	clientId: string,
	integrationData: IntegrationData,
	fileFetchingDetails: RecmanFileFetchingDetails
) => {
	const response = await forwardWithSecurity({
		clientId,
		platform: "recman",
		url: BASE_RECMAN_URL("get"),
		method: "get",
		queryStringParameters: {
			scope: "candidateFile",
			candidateId: fileFetchingDetails.candidateId,
			fileId: fileFetchingDetails.fileId,
		},
		returnType: "json",
	})
	const file = response.data as {
		base64: string
		extension: AcceptedFileType
		name: string
	}
	const fileOut = {
		fileType: file.extension,
		fileMimeType: MIME_TYPES[file.extension],
		b64File: file.base64,
		name: file.name,
	}
	return fileOut
}

type RecmanFileDetail = {
	fileId: string
	name: string
	created: `${number}-${number}-${number} ${number}:${number}:${number}`
	type: "file" | "url"
}

type AcceptableRecmanFileDetail = {
	name: `${string}.${AcceptedFileType}`
	created: `${number}-${number}-${number} ${number}:${number}:${number}`
	type: "file" | "url"
	fileId: string
} & FileListObject

const listFilesFromRecman = async (clientId: string, integrationData: IntegrationData) => {
	const response = await forwardWithSecurity({
		clientId,
		platform: "recman",
		url: BASE_RECMAN_URL("get"),
		method: "get",
		returnType: "json",
		queryStringParameters: {
			scope: "candidate",
			fields: "files",
			candidateId: integrationData.externalId,
		},
	})
	const files = response.data[integrationData.externalId].files as RecmanFileDetail[]
	return files.filter(isAcceptedFile).map((file, i) => ({
		id: `recman-${i}`,
		name: file.name,
		extension: getExtensionFromFileName(file.name) as AcceptedFileType,
		dateCreated: new Date(file.created),
		fileFetchingDetails: { fileId: file.fileId, candidateId: integrationData.externalId } as RecmanFileFetchingDetails,
	}))
}

// const getUserDetailsFromMercury = async (clientId: string, integrationData: IntegrationData) => {
// 	const files = (await forwardWithSecurity({
// 		clientId,
// 		platform: "recman",
// 		url: `${BASE_MERCURY_URL}api/v1/systemUsers/${integrationData.userId}`,
// 		method: "get",
// 		headers: { "tenant-id": integrationData.externalClientId },
// 		returnType: "json",
// 	})) as { firstName: string; lastName: string; internalEmailAddress: string }
// 	return {
// 		name: `${files.firstName} ${files.lastName}`,
// 		email: files.internalEmailAddress,
// 	}
// }

function isAcceptedFile(file: RecmanFileDetail): file is AcceptableRecmanFileDetail {
	return isAcceptedFileType(file.name)
}

function isAcceptedFileType(fileName: string): fileName is AcceptedFileType {
	if (!fileName) return false
	if (!fileName.includes(".")) return false
	const extension = getExtensionFromFileName(fileName).toLowerCase()
	return ".pdf,.doc,.docx,.txt,.pages,.rtf,.odt".includes(extension)
}

function getExtensionFromFileName(fileName: string) {
	return fileName.split(".").pop()
}

const MIME_TYPES = {
	pdf: "application/pdf",
	doc: "application/msword",
	docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	rtf: "application/rtf",
	txt: "text/plain",
	odt: "application/vnd.oasis.opendocument.text",
	pages: "application/x-iwork-pages-sffpages",
}

export const recmanIntegrationData: PlatformIntegrationData<
	"checkForExisting" | "chooseFile" | "fetchFile" | "confirmationPage"
> = {
	parameterMapping: {
		externalClientId: "externalClientId",
		clientId: "clientId",
		externalId: "externalId",
		userId: "userId",
	},
	pages: ["checkForExisting", "chooseFile", "fetchFile", "confirmationPage"],
	props: {
		// setupUser: {
		// 	getUserDetails: getUserDetailsFromMercury,
		// },
		fetchFile: {
			getFile: getFileFromRecman,
		},
		chooseFile: {
			listFiles: listFilesFromRecman,
		},
		checkForExisting: {},
	},
	initialData: {},
}
