import { createContext, useContext, useRef, useState, useEffect } from "react"
import { filesApi } from "libs"

interface OriginalCVContextProps {
	urls: any
	setUrls: (urls: any) => void
	urlsLoaded: boolean
	loadingUrls: any
	appId: string
	showAnon: boolean
	fetchUrls: (showAnon: boolean) => void
	fileList: string[]
	setFileList: (fileList: string[]) => void
	setShowAnonState: (showAnon: boolean) => void
}

export const OriginalCVContext = createContext<OriginalCVContextProps>({} as OriginalCVContextProps)

export function useOriginalCVContext() {
	return useContext(OriginalCVContext)
}

const fileList = ["cv"]

export function OriginalCVContextProvider({
	appId,
	urls,
	setUrls,
	forceLoadUrls = false,
	showAnon,
	setShowAnon,
	children,
	...props
}) {
	const [fileListState, setFileList] = useState(fileList)
	const [urlsLoaded, setUrlsLoaded] = useState(false)
	const loadingUrls = useRef(false)

	function fetchUrls(showAnon) {
		if (showAnon === false || forceLoadUrls) {
			filesApi.get({ appId }).then((urls) => {
				setUrls(urls)
				setUrlsLoaded(true)
			})
		}
	}

	useEffect(() => {
		//Acts like a mount effect when appId changes
		setUrlsLoaded(false)
		fetchUrls(showAnon)
	}, [appId])

	return (
		<OriginalCVContext.Provider
			value={{
				urls,
				setUrls,
				urlsLoaded,
				loadingUrls,
				appId,
				showAnon,
				fetchUrls,
				fileList: fileListState,
				setFileList,
				setShowAnonState: setShowAnon,
				...props,
			}}>
			{children}
		</OriginalCVContext.Provider>
	)
}
