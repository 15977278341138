import { LoaderButton, LoadingSpinner, ifBlurringParent } from "components"
import { useState, useMemo, useCallback } from "react"
import { clientApi, spacedToCamelCase, useHandler, adminUpdateClient } from "libs"
import { CustomDropdown, CardView } from "components"
import { MdOutlineClose } from "react-icons/md"

type IntegrationOutputObject = {
	name: string
	dataType: string
	integrationType?: Integration
	include: string[]
}
type BullhornIntegrationOutputDataObject = {
	outputFileType?: string
	outputType: "bullhorn"
}

type MercuryIntegrationOutputDataObject = {
	outputType: "mercury"
}

type ArcrmIntegrationOutputDataObject = {
	outputType: "arcrm"
}

type RecmanIntegrationOutputDataObject = {
	outputType: "recman"
}

type VincereIntegrationOutputDataObject = {
	outputType: "vincere"
}

type ApiCallIntegrationOutputDataObject = {
	outputType: "apiCall"
	apiCall?: ApiCallParameters
}

type IntegrationOutputDataObject =
	| BullhornIntegrationOutputDataObject
	| MercuryIntegrationOutputDataObject
	| ArcrmIntegrationOutputDataObject
	| RecmanIntegrationOutputDataObject
	| VincereIntegrationOutputDataObject
	| ApiCallIntegrationOutputDataObject

interface ApiCallParameters {
	baseRequestParameters?: object
	includePersistenceData?: string
	outputMapping?: object
	includeFromBody?: object
	includeFromApp?: object
	transform?: object
	url: string
	calls?: Array<{ endpoint?: string } & Partial<Omit<ApiCallParameters, "url">>>
}

type IntegrationObject = IntegrationOutputDataObject & IntegrationOutputObject

type IntegrationSet = { [integrationName: string]: IntegrationObject }

function getOutputDataFromIntegrationObject(integration: IntegrationObject): IntegrationOutputDataObject {
	switch (integration.outputType) {
		case "bullhorn":
			return { outputFileType: integration.outputFileType, outputType: integration.outputType }
		case "apiCall":
			return { apiCall: integration.apiCall, outputType: integration.outputType }
		default:
			return { outputType: integration.outputType }
	}
}

function getOutputObjectFromIntegrationObject(integration: IntegrationObject): IntegrationOutputObject {
	return {
		name: integration.name,
		dataType: integration.dataType,
		integrationType: integration.integrationType,
		include: integration.include,
	}
}

function getCurrentIntegrations(data) {
	return Object.entries<IntegrationOutputObject>(data?.options?.outputs ?? {}).reduce(
		(acc, [key, integration]): { [integrationName: string]: IntegrationOutputObject } => {
			if (integration.integrationType != null) {
				return { ...acc, [key]: { ...integration, ...data?.outputData?.[key] } }
			} else {
				return acc
			}
		},
		{}
	)
}

export function IntegrationsPage({ clientId, data, setData }): JSX.Element {
	const integrations: IntegrationSet = useMemo(() => getCurrentIntegrations(data), [data])
	const setIntegrations = useCallback(
		(integrations: IntegrationSet) => {
			const outputs = Object.fromEntries(
				Object.entries(integrations).map(([key, integration]) => [
					key,
					getOutputObjectFromIntegrationObject(integration),
				])
			)
			const outputData = Object.fromEntries(
				Object.entries(integrations).map(([key, integration]) => [key, getOutputDataFromIntegrationObject(integration)])
			)
			setData({ ...data, options: { ...data.options, outputs }, outputData })
		},
		[data, setData]
	)
	return (
		<div>
			<ApiKeyCreate clientId={clientId} data={data} setData={setData} />
			{Object.entries(integrations).map(([integrationKey, integrationParameters]) => (
				<IntegrationDetails
					clientId={clientId}
					setData={setData}
					data={data}
					integrationParameters={integrationParameters}
					key={integrationParameters.name}
					integrationKey={integrationKey}
				/>
			))}
			<NewIntegration setIntegrations={setIntegrations} clientId={clientId} data={data} setData={setData} />
		</div>
	)
}

type Integration = "bullhorn" | "mercury" | "recman" | "vincere" | "standard" | "arcrm"

const availableIntegrations: { key: Integration; label: string }[] = [
	{ key: "bullhorn", label: "Bullhorn" },
	{ key: "mercury", label: "Mercury" },
	{ key: "arcrm", label: "Arcrm" },
	{ key: "recman", label: "Recman" },
	{ key: "vincere", label: "Vincere" },
	{ key: "standard", label: "Standard" },
]

function IntegrationDetails({ clientId, data, setData, integrationParameters, integrationKey }) {
	switch (integrationParameters.integrationType) {
		case "bullhorn":
			return (
				<BullhornIntegrationDetails
					integrationKey={integrationKey}
					clientId={clientId}
					setData={setData}
					data={data}
					integrationParameters={integrationParameters}
				/>
			)
		case "mercury":
			return (
				<MercuryIntegrationDetails
					integrationKey={integrationKey}
					clientId={clientId}
					setData={setData}
					data={data}
					integrationParameters={integrationParameters}
				/>
			)
		case "arcrm":
			return (
				<ArcrmIntegrationDetails
					integrationKey={integrationKey}
					clientId={clientId}
					setData={setData}
					data={data}
					integrationParameters={integrationParameters}
				/>
			)
		case "recman":
			return (
				<RecmanIntegrationDetails
					integrationKey={integrationKey}
					clientId={clientId}
					setData={setData}
					data={data}
					integrationParameters={integrationParameters}
				/>
			)
		case "vincere":
			return (
				<VincereIntegrationDetails
					integrationKey={integrationKey}
					clientId={clientId}
					setData={setData}
					data={data}
					integrationParameters={integrationParameters}
				/>
			)
		case "standard":
			return (
				<StandardIntegrationDetails
					integrationKey={integrationKey}
					clientId={clientId}
					setData={setData}
					data={data}
					integrationParameters={integrationParameters}
				/>
			)
	}
}

function MercuryIntegrationDetails({ clientId, setData, data, integrationKey, integrationParameters }) {
	const { integrationType, name } = integrationParameters
	const apiKey = useMemo(() => trimApiKey(data?.apiKey), [data])
	return (
		<IntegrationCard
			clientId={clientId}
			data={data}
			setData={setData}
			name={name}
			integrationKey={integrationKey}
			integrationParameters={integrationParameters}>
			<div>
				<b>URL:</b>{" "}
				{apiKey == null || apiKey === "None"
					? "Please create apiKey/secret to view URL"
					: `https://www.app.hireara.ai/entrypoint/integration?apiKey=${apiKey}&secret=${data?.secret}&clientId=${clientId}&platform=${integrationType}&externalClientId=<MERCURY_TENANT_ID>&externalId=<MERCURY_CONTACT_ID>`}
			</div>
			{/* <div>
				<b>File Type Name:</b>
				<input
					type="text"
					style={{ minWidth: "fit-content", width: 300 }}
					value={localOutputFileType}
					onBlur={updateOutputFileType}
					onChange={handleLocalOutputFileType}></input>
			</div> */}
		</IntegrationCard>
	)
}

function RecmanIntegrationDetails({ clientId, setData, data, integrationKey, integrationParameters }) {
	const { integrationType, name } = integrationParameters
	const apiKey = useMemo(() => trimApiKey(data?.apiKey), [data])
	const [recmanApiKey, handleRecmanApiKey] = useHandler(data?.externalSecrets?.recmanApiKey)

	const updateIntegration = useCallback(
		(e) => {
			const newRecmanApiKey = e.target.value
			clientApi
				.patch({ clientId, updates: { externalSecrets: { recmanApiKey: newRecmanApiKey } }, changesOnly: true })
				.then(() => {
					setData({ ...data, externalSecrets: { ...data?.externalSecrets, recmanApiKey: newRecmanApiKey } })
				})
				.catch(() => {
					alert("Failed to add. Refresh page to check current data")
				})
		},
		[clientId, data, setData]
	)

	return (
		<IntegrationCard
			clientId={clientId}
			data={data}
			setData={setData}
			name={name}
			integrationKey={integrationKey}
			integrationParameters={integrationParameters}>
			<div>
				<b>URL:</b>{" "}
				{apiKey == null || apiKey === "None"
					? "Please create apiKey/secret to view URL"
					: `https://www.app.hireara.ai/entrypoint/integration?apiKey=${apiKey}&secret=${data?.secret}&clientId=${clientId}&platform=${integrationType}&externalClientId=<RECMAN_CLIENT_ID>&externalId=<RECMAN_CANDIDATE_ID>&userId=<RECMAN_USER_ID>`}
			</div>
			<div>
				<b>Recman API key:</b>
				<input
					type="text"
					style={{ minWidth: "fit-content", width: 300 }}
					value={recmanApiKey ?? ""}
					onBlur={updateIntegration}
					onChange={handleRecmanApiKey}></input>
			</div>
		</IntegrationCard>
	)
}

function VincereIntegrationDetails({ clientId, setData, data, integrationKey, integrationParameters }) {
	const { name } = integrationParameters
	const [vincereApiKey, handleVincereApiKey] = useHandler(data?.externalSecrets?.vincereApiKey)
	const [vincereClientId, handleVincereClientId] = useHandler(data?.externalSecrets?.vincereClientId)
	const [vincereTenant, handleVincereTenant] = useHandler(
		data?.externalId != null ? data?.externalId?.split("#")[1] : ""
	)

	const updateIntegrationApiKey = useCallback(
		(e) => {
			const newVincereApiKey = e.target.value.trim()
			clientApi
				.patch({
					clientId,
					updates: {
						externalSecrets: { vincereApiKey: newVincereApiKey },
					},
					changesOnly: true,
				})
				.then(() => {
					setData({
						...data,
						externalSecrets: { ...data?.externalSecrets, vincereApiKey: newVincereApiKey },
					})
				})
				.catch(() => {
					alert("Failed to add. Refresh page to check current data")
				})
		},
		[clientId, data, setData]
	)
	const updateIntegrationClientId = useCallback(
		(e) => {
			const newVincereClientId = e.target.value.trim()
			clientApi
				.patch({
					clientId,
					updates: {
						externalSecrets: { vincereClientId: newVincereClientId },
					},
					changesOnly: true,
				})
				.then(() => {
					setData({
						...data,
						externalSecrets: { ...data?.externalSecrets, vincereClientId: newVincereClientId },
					})
				})
				.catch(() => {
					alert("Failed to add. Refresh page to check current data")
				})
		},
		[clientId, data, setData]
	)

	const updateIntegrationTenant = useCallback(
		(e) => {
			const newVincereTenant = e.target.value.trim()
			clientApi
				.patch({
					clientId,
					updates: {
						externalId: `vnc#${newVincereTenant}`,
					},
					changesOnly: true,
				})
				.then(() => {
					setData({
						...data,
						externalId: `vnc#${newVincereTenant}`,
					})
				})
				.catch(() => {
					alert("Failed to add. Refresh page to check current data")
				})
		},
		[clientId, data, setData]
	)

	return (
		<IntegrationCard
			clientId={clientId}
			data={data}
			setData={setData}
			name={name}
			integrationKey={integrationKey}
			integrationParameters={integrationParameters}>
			<div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
				<div>
					<b>Vincere API key:</b>
					<input
						type="text"
						style={{ minWidth: "fit-content", width: 300 }}
						value={vincereApiKey ?? ""}
						onBlur={updateIntegrationApiKey}
						onChange={handleVincereApiKey}></input>
				</div>
				<div>
					<b>Vincere Client ID:</b>
					<input
						type="text"
						style={{ minWidth: "fit-content", width: 300 }}
						value={vincereClientId ?? ""}
						onBlur={updateIntegrationClientId}
						onChange={handleVincereClientId}></input>
				</div>
				<div>
					<b>Vincere Tenant (base url):</b>
					<input
						type="text"
						style={{ minWidth: "fit-content", width: 300 }}
						value={vincereTenant ?? ""}
						onBlur={updateIntegrationTenant}
						onChange={handleVincereTenant}></input>
				</div>
			</div>
		</IntegrationCard>
	)
}
function ArcrmIntegrationDetails({ clientId, setData, data, integrationKey, integrationParameters }) {
	const { name } = integrationParameters
	const [arcrmApiKey, handleArcrmApiKey] = useHandler(data?.externalSecrets?.arcrmApiKey)
	const [arcrmTenant, handleArcrmTenant] = useHandler(data?.externalSecrets?.arcrmTenant)

	const updateIntegrationApiKey = useCallback(
		(e) => {
			const newArcrmApiKey = e.target.value.trim()
			clientApi
				.patch({
					clientId,
					updates: {
						externalSecrets: { arcrmApiKey: newArcrmApiKey },
					},
					changesOnly: true,
				})
				.then(() => {
					setData({
						...data,
						externalSecrets: { ...data?.externalSecrets, arcrmApiKey: newArcrmApiKey },
					})
				})
				.catch(() => {
					alert("Failed to add. Refresh page to check current data")
				})
		},
		[clientId, data, setData]
	)

	const updateIntegrationTenant = useCallback(
		(e) => {
			const newArcrmTenant = e.target.value.trim()
			clientApi
				.patch({
					clientId,
					updates: {
						externalSecrets: { arcrmTenant: newArcrmTenant },
					},
					changesOnly: true,
				})
				.then(() => {
					setData({
						...data,
						externalSecrets: { ...data?.externalSecrets, arcrmTenant: newArcrmTenant },
					})
				})
				.catch(() => {
					alert("Failed to add. Refresh page to check current data")
				})
		},
		[clientId, data, setData]
	)

	return (
		<IntegrationCard
			clientId={clientId}
			data={data}
			setData={setData}
			name={name}
			integrationKey={integrationKey}
			integrationParameters={integrationParameters}>
			<div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
				<div>
					<b>Arcrm API key:</b>
					<input
						type="text"
						style={{ minWidth: "fit-content", width: 300 }}
						value={arcrmApiKey ?? ""}
						onBlur={updateIntegrationApiKey}
						onChange={handleArcrmApiKey}></input>
				</div>
				<div>
					<b>Arcrm Tenant (base url):</b>
					<input
						type="text"
						style={{ minWidth: "fit-content", width: 300 }}
						value={arcrmTenant ?? ""}
						onBlur={updateIntegrationTenant}
						onChange={handleArcrmTenant}></input>
				</div>
			</div>
		</IntegrationCard>
	)
}

function BullhornIntegrationDetails({ clientId, setData, data, integrationKey, integrationParameters }) {
	const { name, outputFileType } = integrationParameters
	const apiKey = useMemo(() => trimApiKey(data?.apiKey), [data])
	const [localOutputFileType, handleLocalOutputFileType] = useHandler(outputFileType)
	const updateIntegration = useCallback(
		(newOutputs: IntegrationOutputObject, newOutputData) => {
			const newOptions = { ...data.options, outputs: { ...data.options.outputs, ...newOutputs } }
			const newData = { options: newOptions, outputData: { ...data.outputData, ...newOutputData } }
			clientApi
				.patch({ clientId, updates: { options: { ...newData.options }, outputData: { ...newData.outputData } } })
				.then(() => {
					setData({ ...data, ...newData })
				})
				.catch(() => {
					alert("Failed to add. Refresh page to check current data")
				})
		},
		[clientId, data, setData]
	)

	const updateOutputFileType = useCallback(() => {
		updateIntegration({} as IntegrationOutputObject, {
			[integrationKey as string]: { outputFileType: localOutputFileType, outputType: "bullhorn" },
		})
	}, [localOutputFileType, integrationKey, updateIntegration])

	return (
		<IntegrationCard
			clientId={clientId}
			data={data}
			setData={setData}
			integrationKey={integrationKey}
			name={name}
			integrationParameters={integrationParameters}>
			<div>
				<b>URL:</b>{" "}
				{apiKey == null || apiKey === "None"
					? "Please create apiKey/secret to view URL"
					: `https://www.app.hireara.ai/bullhorn/${apiKey}/${data?.secret}`}
			</div>
			<div>
				<b>File Type Name:</b>
				<input
					type="text"
					style={{ minWidth: "fit-content", width: 300 }}
					value={localOutputFileType}
					onBlur={updateOutputFileType}
					onChange={handleLocalOutputFileType}></input>
			</div>
		</IntegrationCard>
	)
}

function StandardIntegrationDetails({ clientId, setData, data, integrationKey, integrationParameters }) {
	const { name, apiCall } = integrationParameters
	const [localOutputUrl, handleLocalOutputUrl] = useHandler(apiCall?.url)
	let [localOutputHeaders, setLocalOutputHeaders] = useState<Array<[string, string]>>(
		Object.entries(apiCall?.baseRequestParameters.headers ?? {})
	)
	const updateIntegration = useCallback(
		(newOutputs: IntegrationOutputObject, newOutputData) => {
			const newOptions = { ...data.options, outputs: { ...data.options.outputs, ...newOutputs } }
			const newData = { options: newOptions, outputData: { ...data.outputData, ...newOutputData } }
			clientApi
				.patch({
					clientId,
					updates: { options: { ...newData.options }, outputData: { ...newData.outputData } },
					changesOnly: true,
				})
				.then(() => {
					setData({ ...data, ...newData })
				})
				.catch(() => {
					alert("Failed to add. Refresh page to check current data")
				})
		},
		[clientId, data, setData]
	)

	const updateOutputHeaders = useCallback(() => {
		updateIntegration({} as IntegrationOutputObject, {
			[integrationKey as string]: {
				outputType: "apiCall",
				apiCall: {
					...data.outputData[integrationKey]?.apiCall,
					baseRequestParameters: {
						...data.outputData[integrationKey]?.apiCall?.baseRequestParameters,
						headers: Object.fromEntries(
							localOutputHeaders.filter(([k, v]) => k.length > 0 && (v === null || v.length > 0))
						), // null value used to delete
					},
				},
			},
		})
	}, [data.outputData, integrationKey, localOutputHeaders, updateIntegration])

	const updateOutputUrl = useCallback(() => {
		updateIntegration({} as IntegrationOutputObject, {
			[integrationKey as string]: {
				outputType: "apiCall",
				apiCall: {
					...data.outputData[integrationKey]?.apiCall,
					url: localOutputUrl,
				},
			},
		})
	}, [updateIntegration, integrationKey, data.outputData, localOutputUrl])

	return (
		<IntegrationCard
			clientId={clientId}
			data={data}
			setData={setData}
			integrationKey={integrationKey}
			name={name}
			integrationParameters={integrationParameters}>
			<div>
				<b>Output Endpoint Url:</b>
				<input
					type="text"
					style={{ minWidth: "fit-content", width: 500 }}
					value={localOutputUrl}
					onBlur={updateOutputUrl}
					onChange={handleLocalOutputUrl}></input>
			</div>
			<div>
				<b>Headers:</b>
				<div
					tabIndex={0}
					onBlur={ifBlurringParent(updateOutputHeaders)}
					style={{ display: "flex", flexDirection: "column", gap: 10 }}>
					{localOutputHeaders.map(([key, value], index) => {
						const handleChange = (isKey) => (e) => {
							const newHeaders = [...localOutputHeaders]
							newHeaders[index] = isKey ? [e.target.value, value] : [key, e.target.value]
							setLocalOutputHeaders(newHeaders)
						}
						return (
							<div key={`header-${index}`} style={{ display: "flex", gap: "5px" }}>
								<input
									type="text"
									style={{ minWidth: "fit-content", width: 300 }}
									value={key}
									onChange={handleChange(true)}></input>
								:
								<input
									type="text"
									style={{ minWidth: "fit-content", width: 300 }}
									value={value}
									onChange={handleChange(false)}></input>
								<div>
									<MdOutlineClose
										onClick={() => {
											localOutputHeaders = localOutputHeaders.map(([k, v], i) => (i !== index ? [k, v] : [k, null]))
											updateOutputHeaders()
											setLocalOutputHeaders(localOutputHeaders.filter((_, i) => i !== index))
										}}
										className="clickable"
										size={24}
										style={{ margin: "auto" }}
									/>
								</div>
							</div>
						)
					})}
					<div style={{ textAlign: "center" }}>
						<LoaderButton
							className="SquarerLoaderButton"
							onClick={() => {
								setLocalOutputHeaders([...localOutputHeaders, ["", ""]])
							}}>
							Add New Header
						</LoaderButton>
					</div>
				</div>
			</div>
		</IntegrationCard>
	)
}

function IntegrationCard({
	name,
	clientId,
	setData,
	data,
	integrationKey,
	integrationParameters: { integrationType, dataType, include },
	children,
}) {
	const [isDeleting, setIsDeleting] = useState(false)
	return (
		<CardView style={{ margin: 20 }}>
			<div style={{ position: "absolute", top: 10, right: 10 }}>
				<MdOutlineClose
					onClick={() => {
						const newOutputs = { ...data.options.outputs }
						newOutputs[integrationKey] = null
						const newOutputData = { ...data.outputData }
						newOutputData[integrationKey] = null
						const newData = { options: { ...data.options, outputs: newOutputs }, outputData: newOutputData }
						setIsDeleting(true)
						clientApi
							.patch({
								clientId,
								updates: { options: { outputs: newOutputs }, outputData: newOutputData },
								changesOnly: true,
							})
							.then(() => {
								delete newOutputData[integrationKey]
								delete newOutputs[integrationKey]
								setData({ ...data, ...newData })
							})
							.catch(() => {
								alert("Failed to delete. Refresh page to check current data")
							})
							.finally(() => {
								setIsDeleting(false)
							})
					}}
					className="clickable"
					size={24}
				/>
			</div>
			<h4>{name}</h4>
			<div>Integration Type: {integrationType}</div>
			<div>Data type: {dataType}</div>
			<div>Data to include: {include}</div>
			<h5 style={{ marginTop: 30 }}>Integration Information</h5>
			{isDeleting ? (
				<>
					<LoadingSpinner isLoading={true} /> Deleting
				</>
			) : (
				children
			)}
		</CardView>
	)
}

function NewIntegration({ setIntegrations, clientId, data, setData }) {
	const [newName, handleNewName] = useHandler<string>(null)
	const [newType, handleNewType] = useHandler<Integration | null>(null)

	const createNewIntegration = () => {
		const name = newName || DEFAULT_INTEGRATION_PARAMETERS[newType].name
		const newIntegrationKey = spacedToCamelCase(name)
		const newIntegration = {
			...DEFAULT_INTEGRATION_PARAMETERS[newType],
			name,
			integrationType: newType,
		}
		setIntegrations((integrations: { [name: string]: IntegrationOutputObject }) => ({
			...integrations,
			[newIntegrationKey]: newIntegration,
		}))
		const newOptions = {
			...data.options,
			outputs: { ...data?.options?.outputs, [newIntegrationKey]: getOutputObjectFromIntegrationObject(newIntegration) },
		}
		const newData = {
			options: newOptions,
			outputData: { ...data.outputData, [newIntegrationKey]: getOutputDataFromIntegrationObject(newIntegration) },
		}
		clientApi
			.patch({ clientId, updates: { options: { ...newData.options }, outputData: { ...newData.outputData } } })
			.then(() => {
				setData({ ...data, ...newData })
			})
			.catch(() => {
				alert("Failed to add. Refresh page to check current data")
			})
	}
	return (
		<div style={{ display: "flex", alignItems: "center", gap: 10 }}>
			<div>New integration: </div>
			<input
				type="text"
				value={newName ?? DEFAULT_INTEGRATION_PARAMETERS[newType]?.name ?? ""}
				onChange={handleNewName}></input>
			<CustomDropdown
				value={newType ?? "-"}
				actions={availableIntegrations}
				bestOption={"Choose Type"}
				onChange={handleNewType}
			/>
			<LoaderButton
				style={{ marginTop: 0 }}
				className="SquarerLoaderButton"
				isLoading={false}
				onClick={createNewIntegration}>
				Create
			</LoaderButton>
		</div>
	)
}

const DEFAULT_INTEGRATION_PARAMETERS: {
	[integrationType in Integration]: IntegrationOutputObject & IntegrationOutputDataObject
} = {
	bullhorn: {
		dataType: "formattedPdf",
		include: ["bullhornData"],
		name: "Save to Bullhorn",
		outputFileType: "Formatted Resume (HireAra)",
		outputType: "bullhorn",
	},
	mercury: {
		dataType: "formattedPdf",
		include: ["fileName"],
		name: "Save to Mercury",
		outputType: "mercury",
	},
	arcrm: {
		dataType: "formattedPdf",
		include: ["fileName"],
		name: "Save to ARCRM",
		outputType: "arcrm",
	},
	recman: {
		dataType: "formattedPdf",
		include: ["fileName"],
		name: "Save to Recman",
		outputType: "recman",
	},
	vincere: {
		dataType: "formattedPdf",
		include: ["fileName", "userId"],
		name: "Save to Vincere",
		outputType: "vincere",
	},
	standard: {
		dataType: "formattedPdf&data",
		include: ["fileName"],
		name: "Save to Integration",
		outputType: "apiCall",
		apiCall: {
			baseRequestParameters: { headers: {} },
			includePersistenceData: "json",
			outputMapping: {
				json: {
					data: "data",
					files: {
						cv: {
							b64File: "b64File",
							fileMimeType: "mimeType",
						},
					},
				},
			},
			url: "",
		},
	},
}

function ApiKeyCreate({ clientId, data, setData }) {
	const [isLoading, setIsLoading] = useState(false)
	const apiKey = useMemo(() => trimApiKey(data?.apiKey), [data])
	const createApiKey = async () => {
		setIsLoading(true)
		const { apiKey, secret } = await adminUpdateClient({ updateType: "apiKey", clientId, overwrite: true })
		setIsLoading(false)
		setData({ ...data, apiKey, secret })
	}
	return apiKey != null && apiKey !== "None" ? (
		<div style={{ margin: 20 }}>
			<div>Client ID: {clientId}</div>
			<div>API Key: {apiKey}</div>
			<div>Secret: {data?.secret}</div>
		</div>
	) : (
		<div>
			<LoaderButton className="SquarerLoaderButton" isLoading={isLoading} onClick={createApiKey}>
				Create API Key/Secret
			</LoaderButton>
		</div>
	)
}

function trimApiKey(apiKey: string): string {
	return apiKey?.startsWith("api#") ? apiKey.slice(4) : apiKey
}
